
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AuthProvider } from '~/context/AuthContext';
import { useValidation } from '~/hooks/validation';
import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import React, { ReactNode, useEffect, useState } from 'react';
import { setLocale } from 'yup';
import PiwikProProvider from '@piwikpro/next-piwik-pro';
import { PIWIK_CONTAINER_ID, PIWIK_CONTAINER_URL, ENABLED as PiwikEnabled } from 'config/piwik';
import { Provider } from 'react-redux';
import store from '~/store/index';
import { Banner } from '~/components/Banner/Banner';
import { useRouter } from 'next/router';
import '../styles/globals.css';
import useTranslation from 'next-translate/useTranslation';
const Tracking: React.FC<{
    children: ReactNode;
}> = ({ children }) => {
    if (PiwikEnabled && PIWIK_CONTAINER_ID && PIWIK_CONTAINER_URL) {
        return (<PiwikProProvider containerUrl={PIWIK_CONTAINER_URL} containerId={PIWIK_CONTAINER_ID}>
        {children}
      </PiwikProProvider>);
    }
    return <>{children}</>;
};
function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    const { config } = useValidation();
    const { t } = useTranslation('welcome');
    setLocale(config);
    const router = useRouter();
    const [showLoggedOutMessage, setShowLoggedOutMessage] = useState<boolean>(false);
    function checkForInactivity() {
        const expireTime = localStorage.getItem('expireTime');
        if (Number(expireTime) < Date.now()) {
            router.push('/auth/sign-out');
            setShowLoggedOutMessage(true);
        }
    }
    function updateExpireTime() {
        const updatedExpireTime = Date.now() + (30 * 60 * 1000);
        localStorage.setItem('expireTime', updatedExpireTime.toString());
    }
    function addEventListeners() {
        window.addEventListener('click', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);
        window.addEventListener('touchmove', updateExpireTime);
    }
    function removeEventListeners() {
        window.removeEventListener('click', updateExpireTime);
        window.removeEventListener('keypress', updateExpireTime);
        window.removeEventListener('scroll', updateExpireTime);
        window.removeEventListener('mousemove', updateExpireTime);
        window.removeEventListener('touchmove', updateExpireTime);
    }
    useEffect(() => {
        const currentPath = router.pathname;
        if (currentPath.includes('/auth') === false) {
            addEventListeners();
        }
        const interval = setInterval(() => {
            if (currentPath.includes('/auth') === false) {
                checkForInactivity();
            }
        }, 60000);
        return () => {
            removeEventListeners();
            clearInterval(interval);
        };
    }, [router.pathname]);
    return (<Tracking>
      <RecoilRoot>
        <Provider store={store}>
          <AuthProvider>
            <div>
              <div className="text-base antialiased font-body">
                <Component {...pageProps}/>
              </div>
              <Banner id='auto-logout' open={showLoggedOutMessage} position="top" type='message' showOnce={false} autoClose={false} onClose={() => setShowLoggedOutMessage(false)}>
                <p>{t('cta:automatically-logged-out')}</p>
              </Banner>
            </div>
          </AuthProvider>
        </Provider>
      </RecoilRoot>
    </Tracking>);
}
const __Next_Translate__Page__18949481a07__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18949481a07__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  